import http from './http'

export function view (query, org) {
  return !org
    ? http.get(`/signup/complete?expires=${query.expires}&token=${query.token}&signature=${query.signature}`)
    : http.get(`/organisations/${org}/members/invite/accept?expires=${query.expires}&token=${query.token}&signature=${query.signature}`)
}

export function complete (query, params, org) {
  return !org
    ? http.post(`/signup/complete?expires=${query.expires}&token=${query.token}&signature=${query.signature}`, params)
    : http.post(`/organisations/${org}/members/invite/accept?expires=${query.expires}&token=${query.token}&signature=${query.signature}`, params)
}

export function register (user) {
  return http.post('/register', user)
}

export default { view, complete, register }
