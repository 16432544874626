<script>
import store from 'store'
export default {
  beforeRouteEnter (to, from, next) {
    /**
     * We force a full page reload on logout due to some
     * odd rendering errors with layout switching on mobile.
     */

    store.dispatch('logout', false).then(() => {
      window.location.pathname = to.params?.signup ? '/signup' : '/login'
    })
  }
}
</script>
