<template>
  <div class="verification-wrapper">
    <div class="img-container">
      <img :src="require('assets/mango.png')">
    </div>
    <m-banner
      v-if="validated"
      title="Sign In Successful"
      message="You have been signed in"
    />
    <m-banner
      v-else
      title="Error"
      message="Something went wrong signing in"
      color="negative"
    />
    <div class="button-wrapper">
      <button @click="$router.push({ name: 'home' })">
        Return to Home Screen
      </button>
      <button v-if="validated" @click="$router.push({ name: 'home', hash: '/#mango'})">
        Go to Mango Account
      </button>
    </div>
  </div>
</template>

<script>
import store from 'store'
import { signinMango } from 'api/prepay'
import { MBanner } from 'components/'
import loading from 'utils/loading'

export default {
  name: 'MangoVerification',
  components: { MBanner },
  data () {
    return {
      validated: false
    }
  },
  async beforeRouteEnter (to, from, next) {
    const partner = store.getters.partner
    loading.start({
      message: 'Signing in',
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })
    const code = to.hash?.slice(6)
    const user = store.getters.userLookup

    if (store.getters['mango/getValidated']) {
      next({ name: 'home' }) // already validated
      return
    }

    try {
      await signinMango(user.value, code)
      next(vm => {
        vm.validated = true
        store.dispatch('mango/validateCredentials', true)
      })
    } catch (err) {
      next(vm => {
        vm.validated = false
        store.dispatch('mango/validateCredentials', false)
      })
      // TODO: consider how we handle this error?
    } finally {
      loading.stop()
    }
  }
}
</script>

<style lang="stylus" scoped>
.verification-wrapper
  display flex
  flex-direction column
  align-items center
  justify-content center
  height 100%
  & .q-banner
    width 600px
    max-width 90vw
.img-container
  max-width 60vw
  margin-inline auto
  margin-block-start 24px
  & img
    width 100%

.button-wrapper
  color black
  display flex
  flex-direction column
  text-align center
  width 80vw
  margin-inline auto
  & button
    margin-block 8px
    padding-block 8px
    border-radius 8px
    text-transform capitalize
    color var(--q-color-primary)
    border 1px solid
    border-color var(--q-color-primary)
    background-color white
    &:first-of-type
      border none
      background-color var(--q-color-primary)
      color white
</style>
